import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BlogHomeStyles from "../components/Blog/Blog/BlogHome.styles";
import BlogPostThumbnail from "../components/Blog/Blog/BlogPostThumbnail/BlogPostThumbnail";
import Categories from "../components/Blog/Blog/Categories/Categories";
import BlogBg from "../components/Blog/Background/BlogBG";
import Layout from "../components/Layout/Layout";

const {
    Container,
    ContentContainer,
    TitleContainer,
    TitleBoxContainer,
    TitleBox,
    TitleText,
    SubTitleText,
    BlogPostsGrid,
} = BlogHomeStyles;

const BlogList = ({ data, pageContext }) => {
    const categories = [
        "All",
        "Business",
        "HR",
        "Knowledge",
        "Startup",
        "Technologies",
    ];

    console.log("Blog posts", data.allContentfulBlogPost.nodes);
    console.log("numPages", pageContext.numPages);
    console.log("PerPage", pageContext.limit);

    return (
        <Layout>
            <BlogBg />
            <Container>
                <ContentContainer>
                    <TitleContainer>
                        <TitleBoxContainer>
                            <TitleBox>
                                <TitleText>Blog</TitleText>
                            </TitleBox>
                        </TitleBoxContainer>
                    </TitleContainer>
                    <SubTitleText>
                        We are blogging about tech news, startups, leadership in
                        IT organizations and other IT related topics.
                    </SubTitleText>
                    <Categories categories={categories} />
                    <BlogPostsGrid>
                        {/* {blogPostsData.allMdx.nodes.map((post) => { */}
                        {data.allContentfulBlogPost.nodes.map((post) => {
                            return (
                                <BlogPostThumbnail post={post} key={post.id} />
                            );
                        })}
                    </BlogPostsGrid>
                </ContentContainer>
            </Container>
        </Layout>
    );
};

export default BlogList;

export const blogPostsData = graphql`
    query BlogPostsLimit($skip: Int!, $limit: Int!) {
        allContentfulBlogPost(
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                lead
                categories {
                    singleCategory
                }
                title
                id
                createdAt
                slug
                thumbnail {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        cropFocus: CENTER
                        aspectRatio: 1.333
                    )
                }
                content {
                    raw
                }
            }
        }
    }
`;
